<template>
  <v-dialog
      v-model="dialog"
      max-width="600"
      scrollable
  >
    <v-card>
      <v-toolbar dark>
        <v-card-title class="text-h5">
          <span>{{ title }} Resource File</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" md="12">
              <v-text-field
                  v-model="resource_file.title"
                  :error="$v.resource_file.title.$error"
                  outlined
                  dense
              >
                <template v-slot:label>
                  Title <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.resource_file.title.$error">Title is required</span>
              <span class="text-danger" v-if="errors.title" >* {{ errors.title[0] }}</span>
            </v-col>

            <v-col cols="12">
              <v-select
                  item-text="name"
                  item-value="value"
                  :items="types"
                  v-model="resource_file.type"
                  :error="$v.resource_file.type.$error"
                  @input="resource_file.type = $event !== null ? $event : ''"
                  outlined
                  dense
              >
                <template v-slot:label>
                  Type <span class="text-danger">*</span>
                </template>
              </v-select>
              <span class="text-danger" v-if="$v.resource_file.type.$error">Type is required</span>
              <span class="text-danger" v-if="errors.type" >* {{ errors.type[0] }}</span>
            </v-col>

            <v-col cols="12" v-if="resource_file.type == 'file'">
              <a v-if="resource_file.file_path" :href="resource_file.file_path.thumb" target="_blank"></a>
              <v-file-input
                  v-model="resource_file.uploadFile"
                  :error="$v.resource_file.uploadFile.$error"
                  placeholder="File"
                  prepend-icon=""
                  prepend-inner-icon="mdi-file"
                  outlined
                  dense
              >
                <template v-slot:label>
                  Select a File  <span class="text-danger">*</span>
                </template>
              </v-file-input>
              <span class="text-danger" v-if="$v.resource_file.uploadFile.$error">File Field is required</span>
            </v-col>

            <v-col cols="12" v-if="resource_file.type == 'link'">
              <v-text-field
                  v-model="resource_file.link"
                  :error="$v.resource_file.link.$error"
                  placeholder="Link"
                  outlined
                  dense
              >
                <template v-slot:label>
                  Link  <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.resource_file.link.$error">Link Field is required</span>
            </v-col>

            <v-col cols="12" md="6">
              Status
              <v-switch
                  v-model="resource_file.is_active"
                  :label="resource_file.is_active ? 'Active' : 'Inactive'"
              ></v-switch>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
            text
            @click="closeDialog"
        >
          Cancel
        </v-btn>

        <v-btn
            class="btn btn-primary"
            @click="createOrUpdate()"
            :loading="loading"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {required, requiredIf} from "vuelidate/lib/validators";
import ResourceFileService from "@/services/cms/resource/File/ResourceFileService";

const resourceFile = new ResourceFileService();

export default {
  name: "CreateAndUpdate",
  data() {
    return {
      title: '',
      edit: false,
      dialog: false,
      loading: false,
      folderId: '',
      errors: [],
      types: [
        { name: "File", value: "file" },
        { name: "Link", value: "link" },
      ],
      resource_file: {
        title: '',
        type: '',
        uploadFile: null,
        file: '',
        link: '',
        folder_id: '',
        is_active: true,
      },

      editorConfig: {
        versionCheck: false,
        toolbar: [
            [ 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList' , 'Source','-','Save','NewPage','DocProps','Preview','Print','-','Templates'],
        ]
      }
    }
  },
  mounted() {
    this.folderId = this.$route.params.folderId;
  },
  validations: {
    resource_file: {
      title: { required },
      type: { required },
      uploadFile: { required: requiredIf(function () {
        return this.resource_file.type == 'file'
        }) },
      link: { required: requiredIf(function () {
          return this.resource_file.type == 'link'
        }) },
      is_active: { required }
    }
  },
  methods: {
    closeDialog(){
      this.dialog = false;
    },
    createResourceFile(){
      this.title = 'Add';
      this.dialog = true;
      this.edit = false;
      this.resetForm();
    },
    editResourceFile(resource_file){
      this.title = "Edit"
      this.dialog = true;
      this.edit = true;
      this.resource_file = resource_file;
    },
    convertToFormData() {
      let formData = new FormData();
      this.resource_file.folder_id = this.folderId;
      for (let key in this.resource_file) {
        if (key === "uploadFile" && this.resource_file[key] != null && this.resource_file[key] != undefined) {
          formData.append('uploadFile', this.resource_file[key]);
        }else {
          if(this.resource_file[key]){
            formData.append(key,this.resource_file[key]);
          }
        }
      }
      return formData;
    },
    createOrUpdate() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      } else {
        this.loading = true;
        if (!this.edit) this._create();
        else this.update();
      }
    },
    _create: function() {
      let formData = this.convertToFormData();
      resourceFile
          .create(formData)
          .then(response =>{
            this.loading = false;
            this.$snotify.success("Resource File created successfully");
            this.resetForm();
            this.closeDialog();
            this.$emit('refresh');
          })
          .catch((err) => {
            this.loading = false;
            this.$snotify.error("Oops something went wrong");
            this.errors = err.errors;
          });
    },
    update() {
      let formData = this.convertToFormData();
      resourceFile
          .update(this.resource_file.id,formData)
          .then(response => {
            this.loading = false;
            this.$snotify.success("Resource File updated successfully");
            this.resetForm();
            this.closeDialog();
            this.$emit('refresh');
          })
          .catch((err) => {
            this.loading = false;
            this.$snotify.error("Oops something went wrong");
            this.errors = err.errors;
          });
    },
    resetForm() {
      this.$v.$reset();
      this.resource_file = {
        title: '',
        type: '',
        uploadFile: null,
        file: '',
        link: '',
        folder_id: '',
        is_active: true,
      }
    }
  }
}
</script>

<style scoped>

</style>
