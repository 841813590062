<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Resource File</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Resource File
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn
                      @click="createResourceFile()"
                      class="mt-1 btn btn-primary"
                  >
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                    Add Resource File
                  </v-btn>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                        label="Name"
                        v-model="search.title"
                        v-on:keyup.enter="searchResourceFile"
                        @input="search.title = $event !== null ? $event : ''"
                        clearable
                        outlined
                        dense
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="4">
                    <v-select
                        label="Status"
                        v-model="search.is_active"
                        v-on:keyup.enter="searchResourceFile"
                        @input="search.is_active = $event !== null ? $event : ''"
                        :items="status"
                        item-text="name"
                        item-value="value"
                        clearable
                        outlined
                        dense
                    ></v-select>
                  </v-col>

                  <v-col cols="12" sm="6" md="4">
                    <v-btn
                        @click.prevent="searchResourceFile"
                        v-on:keyup.enter="searchResourceFile"
                        class="btn btn-primary"
                        :loading="loading"
                    >
                      <v-icon small  outlined>fas fa-search</v-icon>&nbsp;
                      Search
                    </v-btn>
                  </v-col>
                </v-row>
            </div>

            <!-- table -->
            <div class="table-responsive">

              <v-skeleton-loader
                  v-if="loading"
                  type="table-thead"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="loading"
                  type="table-row-divider@25"
              >
              </v-skeleton-loader>

              <table class="table" v-if="!loading">
                <thead>
                <tr class="px-3">
                  <th><strong>Title</strong></th>
                  <th><strong>Type</strong></th>
                  <th><strong>Folder</strong></th>
                  <th><strong>File/ Link</strong></th>
                  <th><strong>Status</strong></th>
                  <th class="pr-3 text-center"><strong>Action</strong></th>
                </tr>
                </thead>

                <template>
                  <draggable class="w-100" v-model="resourceFiles" @change="sort" @start="drag=true" tag="tbody" @end="drag=false">
                    <tr v-for="(resourceFile, index) in resourceFiles" :key="index">
                      <td>
                        <a @click="editResourceFile(resourceFile)" class="text-primary font-weight-bolder text-hover-primary mb-1 font-size-lg">
                          <i class="fa fa-sort"></i> {{ resourceFile.title }}
                        </a>
                      </td>
                      <td>
                        {{ resourceFile.type }}
                      </td>
                      <td>
                        {{ resourceFile.folder }}
                      </td>
                      <td>
                        <a class="font-weight-bolder" v-if="resourceFile.file" :href="resourceFile.file_path.real" target="_blank">
                         File
                        </a>
                        <a class="font-weight-bolder" :href="resourceFile.link" v-else target="_blank">
                          Link
                        </a>
                      </td>
                      <td class="px-3" >
                        <span class="badge badge-success text-lg`"
                              v-bind:class="{ 'badge-success': resourceFile.is_active, 'badge-danger': !resourceFile.is_active }"
                        >{{ resourceFile.is_active ? 'Active' : 'Inactive' }}</span>
                      </td>

                      <td class="pr-0 text-left">
                        <template>
                          <b-dropdown
                              size="sm"
                              variant="link"
                              toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                              no-caret
                              right
                              no-flip
                          >
                            <template v-slot:button-content>
                              <i class="ki ki-bold-more-hor"></i>
                            </template>
                            <!--begin::Navigation-->
                            <div class="navi navi-hover min-w-md-250px">
                              <b-dropdown-text tag="div" class="navi-item" v-if="resourceFile.type == 'link'">
                                <a class="navi-link" @click="editResourceFile(resourceFile)">
                                  <span class="navi-icon">
                                      <v-icon color="blue darken-2">fas fa-pen</v-icon>
                                  </span>
                                  <span class="navi-text">Edit</span>
                                </a>
                              </b-dropdown-text>
                              <b-dropdown-text tag="div" class="navi-item">
                                <a class="navi-link" @click.prevent="deleteResourceFile(resourceFile.id)">
                                  <span class="navi-icon">
                                      <v-icon color="red darken-2">fas fa-trash-alt</v-icon>
                                  </span>
                                  <span class="navi-text">Delete</span>
                                </a>
                              </b-dropdown-text>
                            </div>
                            <!--end::Navigation-->
                          </b-dropdown>
                        </template>
                      </td>
                    </tr>
                    <tr v-if="resourceFiles.length == 0">
                      <td class="text-center font-weight-bold px-3" colspan="6">No Data Found</td>
                    </tr>
                  </draggable>
                </template>

              </table>

            </div>
            <!-- table ends -->
            <v-overlay :value="isSorting">
              <v-progress-circular
                  indeterminate
                  color="dark"
              ></v-progress-circular>
            </v-overlay>

          </div>
        </div>
      </div>
      <create-and-update ref="create-and-update" @refresh="getAllResourceFiles"></create-and-update>
    </div>
  </v-app>
</template>
<script>
import draggable from "vuedraggable";
import CreateAndUpdate from "@/view/pages/view/cms/resource/file/CreateAndUpdate";
import ResourceFileService from "@/services/cms/resource/File/ResourceFileService";

const resourceFile = new ResourceFileService();

export default {
  components: {
    CreateAndUpdate,
    draggable
  },
  data() {
    return{
      resourceFiles:[],
      folderId: '',
      total: null,
      perPage: null,
      page: null,
      loading: false,
      isSorting: false,
      status: [
        {name: 'All', value: ''},
        {name: 'Active', value: '1'},
        {name: 'Inactive', value: '0'},
      ],
      search:{
        title:'',
        folder_id:'',
        is_active:'',
      }
    }
  },
  mounted() {
    this.folderId = this.$route.params.folderId;
    this.getAllResourceFiles();
  },
  methods: {
    createResourceFile(){
      this.$refs['create-and-update'].createResourceFile();
    },
    editResourceFile(resourceFile){
      this.$refs['create-and-update'].editResourceFile(resourceFile);
    },
    getAllResourceFiles(){
      this.loading = true;
      this.search.folder_id = this.folderId;
      resourceFile
      .paginate(this.search, this.page)
      .then(response => {
        this.resourceFiles = response.data.data;
        this.page = response.data.meta.current_page;
        this.total = response.data.meta.total;
        this.perPage = response.data.meta.per_page;
        this.loading = false;
      })
          .catch((err) => {
            this.loading = false;
            this.$snotify.error("Oops something went wrong");
          });
    },
    sort() {
      this.isSorting = true;
      resourceFile
          .sort(this.resourceFiles)
          .then(response => {
            this.isSorting = false;
            this.$snotify.success('Resource File Sorted !!');
            this.getAllResourceFiles();
          })
          .catch(err => {
            this.isSorting = false;
            this.$snotify.error("Oops something went wrong");
          });
    },

    searchResourceFile(){
      this.getAllResourceFiles();
    },
    deleteResourceFile(id) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            resourceFile
                .delete(id)
                .then((response) => {
                  this.getAllResourceFiles()
                  this.$snotify.success("Resource File Deleted Successfully ");
                })
                .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
    }
  }
}
</script>